import React, { useContext } from 'react'
import NewsItem from './NewsItem'
import NewsContext from '../Context/NewsContext'

function News() {
  const context =useContext(NewsContext);
  const {allnews,SetNews} =context
  return (
   <>
    <div className="container my-3">
      <h2>Top News</h2>
      {allnews.map((allnews)=>{
        return (
          <>
          <NewsItem news={allnews}/>
          </>
        )
      })}
    </div>
   </>
  )
}

export default News
