/* eslint-disable no-dupe-keys */
import React from "react";

export default function Footer() {
  return (
    <>
      <footer
        className="bg-secondary"
        style={{
          // backgroundImage:
          //   "url(https://www.bankrate.com/brp/2023/10/16124824/Investments-Stock-market-basics_9-tips-for-beginners.jpg)",
          backgroundSize:"cover",
          backgroundRepeat: "no-repeat",
          opacity:"0.7"
        }}
      >
        <div
          className="container-fulid row py-5  mx-5 mt-5 overflow-hidden"
          style={{
            backdropFilter: "blur(20px)",
            WebkitBackdropFilter: "blur(20px)",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "rgb(rgb(171 156 124 / 18%)",
            
          }}
        >
          <div className="myDetails col-12 col-md-3">
            <h3 className="text-light">Stock Dev</h3>
            <p className="text-light">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur,
              natus?
            </p>
          </div>
          <div className="col-12 col-md-3  ">
            <ul className="">
              <li className="nav-link">
                <a href="/" className="text-light nav-link">
                  Stock News
                </a>
              </li>
              <li className="nav-link">
                <a href="/" className="text-light nav-link">
                  Swing News
                </a>
              </li>
              <li className="nav-link">
                <a href="/" className="text-light nav-link">
                  IPO
                </a>
              </li>
              <li className="nav-link">
                <a href="/" className="text-light nav-link">
                  Market Updates
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-3 ">
            <ul>
              <li className="nav-link">
                <a href="/" className="text-light nav-link">
                  Abuot
                </a>
              </li>
              <li className="nav-link">
                <a href="/" className="text-light nav-link">
                  Content Us
                </a>
              </li>
              <li className="nav-link">
                <a href="/" className="text-light nav-link">
                  Privacy policy
                </a>
              </li>
              <li className="nav-link">
                <a href="/" className="text-light nav-link">
                  Security
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-3 ">
            <h4 className="text-light">Contant us</h4>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Someone Massege"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <span className="input-group-text bg-dark" id="basic-addon2">
                <a href="https://t.me/+Lnv_t3S-rz0xZGI1">
                  <img
                    src="https://img.icons8.com/?size=40&id=Eawzmcklku9D&format=png&color=000000"
                    alt=""
                  />
                </a>
                <img src="" alt="" />
              </span>
              <div className="my-3">
                <ul className="d-flex ">
                  <li className="mx-md-2 nav-link">
                    <a href="/">
                      <img
                        src="https://img.icons8.com/?size=40&id=fJp7hepMryiw&format=png&color=FFFFFF"
                        alt="Twitter"
                      />
                    </a>
                  </li>
                  <li className="mx-md-2 nav-link">
                    <a href="/">
                      <img
                        src="https://img.icons8.com/?size=40&id=32292&format=png&color=FFFFFF"
                        alt="Instagram"
                      />
                    </a>
                  </li>
                  <li className="mx-md-2 nav-link">
                    <a href="/">
                      <img
                        src="https://img.icons8.com/?size=40&id=118468&format=png&color=FFFFFF"
                        alt="Fasebook"
                      />
                    </a>
                  </li>
                  <li className="mx-md-2 nav-link">
                    <a href="/">
                      <img
                        src="https://img.icons8.com/?size=40&id=TCnKnYZFoOzM&format=png&color=FFFFFF"
                        alt="Telegram"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="text-light" />
          <span className="text-center text-light">
            Copyright &copy; 2024 thestockcity.com
          </span>
        </div>
      </footer>
    </>
  );
}
