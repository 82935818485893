import React, { useState } from "react";
import NewsContext from "./NewsContext";

const NewsState = (props)=>{
    const allnewsInitial=[
        {
          "_id": "66bed4ac7bc88f1312fc6a39",
          "title": "Saraswati IPO GMP ",
          "description": "Check out Saraswati Saree Depot IPO GMP aka IPO Grey Market Premium, Kostak rates, and Subject to Sauda rates as of today. Saraswati Saree Depot IPO GMP not started yet. Stay tuned for the latest IPO GMP numbers of Saraswati Saree Depot IPO.",
          "fulldetails": "Saraswati Saree Depot IPO is to open on August 12. Saraswati Saree Depot is a Mainline IPO to raise ₹160.01 crores via IPO. The Saraswati Saree Depot IPO price band is fixed at ₹152 to ₹160 with a market lot of [.] Shares. Saraswati Saree Depot is a key player in the sarees wholesale (B2B) segment (Source: CRISIL Report) and their origin in the sarees business dates back to the year 1966. They are also engaged in the wholesale business of other women’s apparel wear such as kurtis, dress materials, blouse pieces, lehengas, bottoms, etc. On average more than 90% of their total revenues are generated from the sale of sarees GMP 13 August : 65 ₹, 12 August : 35₹ ,11 August : 20 ₹",
          "category": "IPO",
          "__v": 0
        },
        {
          "_id": "66bed51f7bc88f1312fc6a3d",
          "title": "Ola electric give. 70% return in just 3 days of listing. Now what will happen?",
          "description": "Ola Electric share price has been ascending continuously after listing on Dalal Street on Friday last week. Though Ola Electric share price had a flat listing, the stock has witnessed strong buying since listing. Ola Electric share price today opened upside and touched an intraday high of ₹129.40 apiece on the NSE, registering around a 70 percent rise against the listing price of ₹76 apiece. ",
          "fulldetails": "According to company's Red Herring Prospectus (RHP), internal accruals and long-term borrowings made available by its subsidiary Ola Cell Technologies Pvt Ltd (OCT) would finance Phases 1 (a) and 1 (b) of the establishment and expansion of the Ola Gigafactory in the Krishnagiri district of Tamil Nadu. An exchange filing stated that Ola Electric Mobility's board meeting is scheduled to be held on Wednesday, August 14, 2024, inter alia, to consider and approve the unaudited standalone and consolidated financial results of the company for the quarter ended June 30, 2024.",
          "category": "Stock news",
          "__v": 0
        },
        {
          "_id": "66c03100e83829170eb61a61",
          "title": "Ola electric give. 70% return in just 3 days of listing. Now what will happen?",
          "description": "Ola Electric share price has been ascending continuously after listing on Dalal Street on Friday last week. Though Ola Electric share price had a flat listing, the stock has witnessed strong buying since listing. Ola Electric share price today opened upside and touched an intraday high of ₹129.40 apiece on the NSE, registering around a 70 percent rise against the listing price of ₹76 apiece. ",
          "fulldetails": "According to company's Red Herring Prospectus (RHP), internal accruals and long-term borrowings made available by its subsidiary Ola Cell Technologies Pvt Ltd (OCT) would finance Phases 1 (a) and 1 (b) of the establishment and expansion of the Ola Gigafactory in the Krishnagiri district of Tamil Nadu. An exchange filing stated that Ola Electric Mobility's board meeting is scheduled to be held on Wednesday, August 14, 2024, inter alia, to consider and approve the unaudited standalone and consolidated financial results of the company for the quarter ended June 30, 2024.",
          "category": "Stock news",
          "__v": 0
        },
        {
          "_id": "66c035f3187acb25467977e1",
          "title": "Ola electric give. 70% return in just 3 days of listing. Now what will happen?",
          "description": "Ola Electric share price has been ascending continuously after listing on Dalal Street on Friday last week. Though Ola Electric share price had a flat listing, the stock has witnessed strong buying since listing. Ola Electric share price today opened upside and touched an intraday high of ₹129.40 apiece on the NSE, registering around a 70 percent rise against the listing price of ₹76 apiece. ",
          "fulldetails": "According to company's Red Herring Prospectus (RHP), internal accruals and long-term borrowings made available by its subsidiary Ola Cell Technologies Pvt Ltd (OCT) would finance Phases 1 (a) and 1 (b) of the establishment and expansion of the Ola Gigafactory in the Krishnagiri district of Tamil Nadu. An exchange filing stated that Ola Electric Mobility's board meeting is scheduled to be held on Wednesday, August 14, 2024, inter alia, to consider and approve the unaudited standalone and consolidated financial results of the company for the quarter ended June 30, 2024.",
          "category": "Stock news",
          "__v": 0
        }
      ]
      const [allnews,setAllNews]=useState(allnewsInitial)
    return (
        <NewsContext.Provider value={{allnews,setAllNews}}>{props.children}</NewsContext.Provider>
    )
}
export default NewsState;