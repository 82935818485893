import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footers";
import NewsState from "./Context/NewsState";
import News from "./Components/News"
import ManuNavbar from "./Components/ManuNavbar";


function App() {
  return (
    <>
      <NewsState>
      <Router>
        <Navbar/>
        <ManuNavbar/>
        <Routes>
          <Route>
            <Route index element={<News/>}/>
            {/* <Route index path="/home" element={<News/>}/>
            <Route index path="/stocknews" element={<News/>}/>
            <Route index path="/swingstock" element={<News/>}/>
            <Route index path="/ipo" element={<News/>}/>
            <Route index path="/marketupdates" element={<News/>}/>
            <Route index path="/about" element={<News/>}/> */}
          </Route>
        </Routes>
        <Footer/>
      </Router>
      </NewsState>
    </>
  );
}

export default App;
