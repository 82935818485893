import React from "react";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <>
      <header className="sticky-top">
        <nav className="navbar" style={{ backgroundColor: "black" }}>
          <div className="container-fluid">
            <button
              className="btn"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasScrolling"
              aria-controls="offcanvasScrolling"
            >
             <img
                src="https://img.icons8.com/?size=50&id=OTxpMqWbm71F&format=png&color=FFFFFF"
                alt=""
              />
            </button>

            <div
              className="offcanvas offcanvas-start"
              data-bs-scroll="true"
              data-bs-backdrop="false"
              tabIndex="-1"
              id="offcanvasScrolling"
              aria-labelledby="offcanvasScrollingLabel"
            >
              <div className="offcanvas-header bg-dark">
                <button
                  type="button"
                  className="btn-close bg-light"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body bg-dark">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a
                      className="nav-link active text-light"
                      aria-current="page"
                      href="/"
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link text-light" href="/">
                      Link
                    </a>
                  </li>
                </ul>
              </div>
            </div>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
              </div>
          
           
            
            {/* <div className="d-flex">
              <Link className="navbar-brand" to="/home">
              <img
                src=""
                alt="logo"
                style={{ width: "40px" }}
                className="mx-3 d-none d-md-block"
              />
            </Link>
            </div> */}

            <Link
              to="/home"
              className="nav-link fs-1 thestockcity"
              style={{ fontWeight: "bold", color: "#007aff" }}
            >
              The Stock City
            </Link>
            <div className="Hotbg">
              <input
                type="text"
                name=""
                className="Hotbg-txt"
                placeholder="Search..."
              />
              <span className="Hotbg-btn">
                <i className="fa fa-search"></i>
              </span>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Navbar;
