import React from "react";

const NewsItem = (props) =>{
    const {news}=props
    return (
      <>
        <div className="container">
          <div className="card mb-3 ">
            <div className="row g-0 NewsItem ">
              <div className="col-md-8">
                <div className="card-body">
                  <h5 className="card-title">{news.title}</h5>
                  <p className="card-text">{news.description}</p>
                  <p className="card-text">
                    <small className="text-body-secondary">
                    </small>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <img src="/" alt="" className="img-fluid rounded-end" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
export default NewsItem
