import React from "react";

export default function ManuList() {
  return (
    <>
      <section className="manulist" style={{ backgroundColor: "rgb(171 156 124 / 18%" }}>
        <div
          className="container-fluid py-2"
          style={{ backgroundColor: ")rgb(171 156 124 / 18%" }}
        >
          <ul
            className="d-flex text-nowrap"
            style={{ overflowX: "scroll", scrollbarWidth: "none", fontWeight:"bold"}}
          >
            <li className="nav-link mx-3">
              <a href="/home" className="nav-link fs-5" >
                Home
              </a>
            </li>
            <li className="nav-link mx-3">
              <a href="/stocknews" className="nav-link fs-5">
                Stock News
              </a>
            </li>
            <li className="nav-link mx-3">
              <a href="/swingstock" className="nav-link fs-5">
                Swing Stock
              </a>
            </li>
            <li className="nav-link mx-3">
              <a href="/ipo" className="nav-link fs-5">
                IPO
              </a>
            </li>
            <li className="nav-link mx-3">
              <a href="/marketupdates" className="nav-link fs-5">
                Market Updates
              </a>
            </li>
            <li className="nav-link mx-3">
              <a href="/about" className="nav-link fs-5">
                About
              </a>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}
